/** Overrides for Material menu. **/

.mdc-menu-surface {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.2) !important;
  border-radius: 0;
  min-height: 43px !important;
  .mdc-list-item {
    height: 32px;
    line-height: 32px;
    min-height: 32px;
    &:hover {
      background-color: var(--ef-menu-item-highlight-color);
      .mdc-list-item__primary-text {
        color: var(--ef-ui-50);
      }
    }
  }
}
.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
    .mdc-list-item--disabled
  ) {
  background: var(--ef-primary-600);
  .mdc-list-item__primary-text {
    color: var(--ef-ui-50);
  }
}
.mat-mdc-option.mat-mdc-option-active {
  background: var(--ef-ui-200);
  .mdc-list-item__primary-text {
    color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  }
}
.mat-mdc-menu-content {
  a {
    text-decoration: none !important;
  }
}
.mat-mdc-menu-panel.no-max-width {
  max-width: 100%;
}

@media (max-width: 767.98px) {
  .mat-mdc-menu-panel.mobile-full-width {
    max-width: unset;
    width: calc(100vw - 45px);
  }
}
