/** Overrides for Material dialog. **/
.mat-mdc-dialog-container .mat-mdc-dialog-title+.mat-mdc-dialog-content {
  padding-top: 1.25em;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: var(--bs-body-color);
  --mdc-dialog-supporting-text-line-height: 1.5em;  
}
.mat-mdc-dialog-container .mdc-dialog__title {
  align-items: center;
  background-color: var(--ef-primary-500);
  border: 2px solid var(--ef-ui-50);
  border-radius: 4px;
  color: var(--ef-ui-50);
  display: flex;
  font-size: 24px;
  padding: 4.5px 6px 4.5px 15px;
  margin: 0 !important;
  & > span {
    flex: 1 1 auto;
  }
}
.mdc-dialog__title::before {
  height: 34px;
}
.mat-mdc-dialog-actions.mdc-dialog__actions {
  border-top: 1px solid var(--ef-ui-300);
  padding: 1em;
}
.mdc-dialog .mdc-dialog__content {
  max-height: calc(95vh - 120px) !important;
  padding: 1.25em 1em;
}

.pm-dialog-sm {
  max-width: 400px !important;
  width: 95%;
}
.pm-dialog-md {
  max-width: 600px !important;
  width: 95%;
}
.pm-dialog-lg {
  max-width: 950px !important;
  width: 95%;
}
.pm-dialog-xl {
  max-width: 1200px !important;
  width: 95%;
}

@media (max-width: 575.98px) {
  .pm-dialog-sm,
  .pm-dialog-md,
  .pm-dialog-lg,
  .pm-dialog-xl {
    width: 100%;
    .mat-mdc-dialog-content {
      max-height: 75vh;
    }
  }
}
