/* This function is used to generate css variables from a material palette. */

@mixin generateColors($prefix, $palette) {
  $colors-map: ();

  @each $key, $value in $palette {
    @if $key !=contrast {
      .ef-#{$prefix}-#{$key} {
        color: map-get($palette, $key);
      }

      $map: ();
      $map: map-merge(
        $map,
        (
          $key: $value,
        )
      );
      $colors-map: map-merge($colors-map, $map);
    }
  }

  :root {
    @each $key, $value in $colors-map {
      --ef-#{$prefix}-#{$key}: #{$value};
    }
  }
}
