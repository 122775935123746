/** Overrides for Material expansion panel. **/

.mat-expansion-panel {
  &:not(:last-of-type)::after {
    content: "";
    display: block;
    border-bottom: 1px solid var(--ef-ui-300);
    margin: 0 20px;
  }
  &:not([class*="mat-elevation-z"]) {
    box-shadow: none;
  }
}

.mat-expansion-panel-header.mat-expanded {
  height: 48px;
}
