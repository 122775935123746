/** Overrides for Material form field. **/
html {
  --mdc-outlined-text-field-focus-label-text-color: var(--ef-accent-400);
  --mdc-outlined-text-field-focus-outline-color: var(--ef-accent-400);
}

.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
  padding: 8px !important;
}
.mat-mdc-form-field-infix {
  border-top: 0 !important;
  min-height: 40px;
  padding: 0 !important;
}
.mat-mdc-form-field .mat-mdc-text-field-wrapper .mat-mdc-floating-label {
  top: 20px !important;
}
.mdc-text-field--outlined {
  background-color: var(--ef-ui-50);
  border-radius: 4px;
  padding-left: max(8px, calc(var(--mdc-shape-small, 4px) + 4px));
  padding-right: max(8px, var(--mdc-shape-small, 4px));
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mdc-notched-outline--upgraded
  .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-26px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(0, 0, 0, 0.75);
}
.mdc-text-field .mdc-text-field__input {
  margin-top: 4px;
}
.mat-mdc-form-field-hint-wrapper {
  flex-direction: row-reverse;
  .mat-mdc-form-field-hint {
    color: var(--ef-ui-600);
    font-style: italic;
    margin-top: -3px;
  }
}
.mat-mdc-form-field-infix {
  width: 100px;
}
.mat-mdc-form-field-textarea-control {
  resize: none;
}
.mat-mdc-form-field.disable-error-outline {
  --mdc-outlined-text-field-error-caret-color: var(
    --mdc-outlined-text-field-caret-color
  );
  --mdc-outlined-text-field-error-focus-label-text-color: var(
    --mdc-outlined-text-field-focus-label-text-color
  );
  --mdc-outlined-text-field-error-focus-outline-color: var(
    --mdc-outlined-text-field-focus-outline-color
  );
  --mdc-outlined-text-field-error-hover-outline-color: var(
    --mdc-outlined-text-field-hover-outline-color
  );
  --mdc-outlined-text-field-error-label-text-color: var(
    --mdc-outlined-text-field-label-text-color
  );
  --mdc-outlined-text-field-error-outline-color: var(
    --mdc-outlined-text-field-outline-color
  );
}
.mat-mdc-form-field-icon-prefix>.mat-icon, .mat-mdc-form-field-icon-suffix>.mat-icon {
  padding: 8px;
}
mat-label.field-required::after {
  content: "*";
  margin-left: 1px;
}