/** Overrides for Material button **/

.mdc-button.mat-alt {
  background-color: var(--ef-ui-100);
}
.mdc-button.mat-link:not(:disabled),
.mdc-icon-button.mat-link:not(:disabled) {
  color: var(--ef-link-color);
}
.mdc-icon-button.mat-mdc-button-base {
  width: 38px;
  height: 38px;
  padding: 7px;
}
.mat-mdc-fab, .mat-mdc-mini-fab {
  box-shadow: none !important;
}
