/** Overrides for Material card **/

.mat-mdc-card {
  --mat-card-title-text-line-height: default;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-weight: 700;
  --mdc-elevated-card-container-elevation: none;
  border: 1px solid var(--ef-ui-300);
  &.notifications {
    mat-card-content {
      pm-alert:not(:last-of-type) {
        border-bottom: 1px solid var(--ef-ui-300);
        margin-bottom: 1em;
        padding-bottom: 1em;
      }
    }
  }
  &.error-card {
    background-color: var(--ef-ui-100);
    .error-message {
      background-color: var(--ef-ui-50);
      border-radius: 4px;
      padding: .5em;
    }
  }
}
