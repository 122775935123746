/** Overrides for Material table **/
html {
  --mat-table-footer-container-height: 40px;
  --mat-table-header-container-height: 40px;
  --mat-table-row-item-container-height: 40px;
}

table.mat-mdc-table {
  border-collapse: separate;

  tr th[role="columnheader"] {
    background: rgb(214, 213, 213);
    background: linear-gradient(180deg,
        var(--ef-table-header-gradient-start) 0%,
        var(--ef-table-header-gradient-end) 100%);
  }
}

table.mat-mdc-table {
  &>thead {
    tr[role="row"] {
      &>th {
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    &>tr>th[role="columnheader"] {
      border-bottom-color: var(--ef-table-border-color);
      border-bottom-width: var(--ef-table-border-width);
      border-bottom-style: solid;
    }
  }

  &>tbody {
    &>tr.mat-mdc-no-data-row {
      height: var(--mat-table-row-item-container-height);

      &>td {
        padding: 0 16px;
        text-align: center;
      }
    }
  }

  &>tfoot[role="rowgroup"] {
    &>tr>td {
      background-color: var(--ef-ui-200);
      border-collapse: collapse;
      border-top-color: var(--ef-table-border-color);
      border-top-width: var(--ef-table-border-width);
      border-top-style: solid;
    }
  }
}

table.reduced-spacing {

  &>tbody>tr>td:not(:first-of-type),
  &>tfoot>tr>td:not(:first-of-type),
  &>thead>tr>th:not(:first-of-type) {
    padding-left: 8px;
  }

  &>tbody>tr>td:not(:last-of-type),
  &>tfoot>tr>td:not(:last-of-type),
  &>thead>tr>th:not(:last-of-type) {
    padding-right: 8px;
  }
}

.pm-table-default {
  >tbody>tr:not(.detail-row)>td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  tr.detail-row {
    height: 0;
  }

  .item-row>td {
    border-bottom-width: 0;
  }

  .item-detail {
    overflow: hidden;
    display: flex;

    .detail-container {
      border-top: 1px solid var(--mat-table-row-item-outline-color);
      padding: 1rem 1rem;
      position: relative;
    }
  }
  .mat-column-Expand, .mat-column-expand {
    color: var(--mat-expansion-header-indicator-color);
    cursor: pointer;
    padding-left: 8px;
    padding-right: 0;
    padding-top: 4px;
    width: 32px;
  }

  .cell-field {
    background-color: var(--ef-ui-50);
    border: solid 1px var(--mdc-outlined-text-field-outline-color);
    border-radius: 4px;
    padding: 5px 6px;

    &:focus {
      outline-color: var(--mdc-outlined-text-field-focus-outline-color);
    }
  }

  .error-cell-field {
    border-color: var(--ef-alert-error-border-color) !important;
    background-color: var(--ef-alert-error-background-color) !important;
  }
  
  .cell-field:not(select) {
    appearance: none;
  }
  .cell-select.mat-form-field-appearance-outline .mat-mdc-form-field-infix {
    min-height: 30px !important;
    padding: 3px !important;
  }
}

@media (max-width: 767.98px) {

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding-left: 8px;
    padding-right: 8px;
  }

  .pm-table-default {
    .mat-column-Expand, .mat-column-expand  {
      width: 32px;
    }
  }
}

table.mat-mdc-table.nested {
  tr th[role="columnheader"] {
    background: transparent;
  }
}

table.mat-mdc-table.nested {
  & > thead {
    & > tr > th[role="columnheader"] {
      border-bottom-style: none;
      font-weight: bold;
    }
  }
  & > tbody {
    & > tr.mat-mdc-no-data-row {
      height: var(--mat-table-row-item-container-height);
      & > td {
        padding: 0 16px;
        text-align: center;
      }
    }
  }

  & .mat-mdc-cell {
    border-bottom-style: none;
  }
}