/** Overrides for Material icon. **/

mat-icon {
  font-size: 24px !important;
  height: 24px !important;
  width: 24px !important;
}

mat-icon.error { color: var(--ef-alert-error-icon-color); }
mat-icon.warning { color: var(--ef-alert-warning-icon-color); }
mat-icon.success { color: var(--ef-alert-success-icon-color); }
mat-icon.action { color: var(--ef-primary-500); }
mat-icon.available { color: var(--ef-alert-available-icon-color); }