/** Overrides for Material snackbar. **/

mat-snack-bar-container.mat-mdc-snack-bar-container {
  &.success-message {
    --mdc-snackbar-container-color: var(--ef-alert-success-background-color);
    --mdc-snackbar-supporting-text-color: var(--ef-ui-default-contrast);
    font-weight: 500;
    margin-top: 140px;

    .mdc-snackbar__surface {
      border: 1px solid var(--ef-alert-success-border-color);
    }

    .mat-mdc-snack-bar-label {
      padding-left: 22px;

      &::before {
        color: var(--ef-alert-success-icon-color);
        content: 'check';
        font-family: 'Material Symbols Outlined';
        font-size: 24px;
        height: 24px;
        left: 14px;
        position: absolute;
        top: 13px;
        width: 24px;
      }
    }
  }

  &.system-failure {
    --mdc-snackbar-container-color: var(--ef-ui-50);
    --mdc-snackbar-supporting-text-color: var(--ef-ui-default-contrast);
    border: 1px solid var(--ef-alert-error-border-color);
    margin-top: 150px;
    max-width: 425px;

    mat-icon {
      color: var(--ef-alert-error-icon-color);
    }
  }

  &.timeout {
    --mdc-snackbar-container-color: var(--ef-ui-50);
    --mdc-snackbar-supporting-text-color: var(--ef-ui-default-contrast);
    border: 1px solid var(--ef-alert-warning-border-color);
    margin-top: 150px;
    max-width: 400px;

    mat-icon {
      color: var(--ef-alert-warning-icon-color);
    }
  }
}