/** Overrides for Material select. **/

.mat-mdc-option .mdc-list-item__primary-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: var(--ef-ui-50);
}