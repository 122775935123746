@use "@angular/material" as mat;

@include mat.all-component-typographies();
@include mat.core();

/* Material Theme */

@import "./core/palette";

$pm-body-1: mat.define-typography-level($font-size: 1rem,
    $letter-spacing: normal,
  );
$pm-buton: mat.define-typography-level($font-size: 1rem,
    $letter-spacing: normal,
    $line-height: 16px,
  );
$practicemanagement-typography: mat.define-typography-config($font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    $body-1: $pm-body-1,
    $button: $pm-buton,
  );

$practicemanagement-theme: mat.define-light-theme((color: (primary: $practicemanagement-primary,
        accent: $practicemanagement-accent,
        warn: $practicemanagement-warn,
      ),
      typography: $practicemanagement-typography,
    ));

@include mat.all-component-themes($practicemanagement-theme);

/* Material overrides */

@import "./material/button";
@import "./material/card";
@import "./material/checkbox";
@import "./material/dialog";
@import "./material/expansion";
@import "./material/form-field";
@import "./material/icon";
@import "./material/menu";
@import "./material/paginator";
@import "./material/progress-spinner";
@import "./material/select";
@import "./material/snackbar";
@import "./material/table";
@import "./material/tooltip";

/* Bootstrap overrides */

.container-xl,
.container-xxl {
  background-color: var(--ef-ui-50);
  padding: 0 15px;
}

/* Global styles */

html,
body {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAFUlEQVQImWNgQAI3b978TyqHgYEBAOC/Dimo4qEvAAAAAElFTkSuQmCC);
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
}

a {
  color: var(--ef-link-color);
  text-decoration: none;

  &:hover {
    color: var(--ef-link-hover-color);
    text-decoration: underline;
  }

  &.disabled {
    color: var(--ef-text-disabled);
    cursor: default;
    pointer-events: none;
    text-decoration: none;
  }
}

hr {
  background-color: var(--ef-ui-300);
  opacity: 1;

  &:not([size]) {
    height: 0.571429px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

textarea {
  line-height: 1.5 !important;
}

.clickable {
  cursor: pointer;
}

.overlay {
  align-items: center;
  background-color: var(--ef-mask-color);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.text-ellipsis,
.menu-item-ellipsis>span.mat-mdc-menu-item-text {
  display: block;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.text-link {
  color: var(--ef-link-color) !important;
}

.text-strike {
  text-decoration: line-through;
}

[class*="pm-panel"] {
  background-color: var(--ef-ui-50);
  border: 1px solid var(--ef-ui-300);
  border-radius: var(--ef-panel-radius);
  overflow: hidden;
  position: relative;
}

.pm-panel {
  &-above-table {
    background-color: var(--ef-ui-100);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-style: none;
    border-color: var(--ef-ui-400);

    &+.pm-panel-table {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-alt {
    background-color: var(--ef-panel-alt-background-color);
  }

  &-light {
    background-color: var(--ef-ui-100);
  }

  &-table {
    background-color: var(--ef-ui-50);
    border-color: var(--ef-ui-400);
  }
}

@import "./core/layout";

.pm-options-table {
  border: 1px solid var(--ef-ui-300);
  border-collapse: separate;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-style: none;
  display: table;

  &>div:not(.pm-options-body) {
    display: table-row;

    &>span {
      position: sticky;
      top: 0;
      background: rgb(214, 213, 213);
      background: linear-gradient(180deg,
          var(--ef-table-header-gradient-start) 0%,
          var(--ef-table-header-gradient-end) 100%);
      border-bottom: 1px solid var(--ef-table-border-color);
      border-top: 1px solid var(--ef-ui-300);
      display: table-cell;
      padding: 0.5rem 1rem;
    }
    
  }
  
  &>.pm-options-body{
    mat-option {
      display: contents;
  
      &>span {
        display: table-row;
  
        &>span {
          border-top: 1px solid var(--mat-table-row-item-outline-color);
          display: table-cell;
          padding: 0.5rem 1rem;
        }
        span.col-elgibility-icon{
          border-top: 1px solid var(--mat-table-row-item-outline-color);
          display: table-cell;
          padding: 0.5rem 1rem;
          text-align: center;
        }
      }
  
      &>mat-pseudo-checkbox {
        display: none;
      }
  
      &.mdc-list-item--selected,
      &:hover {
        &>span {
          background-color:var(--ef-ui-200);
          color: var(--bs-body-color) !important;
        }
      }
    }
  }
}
.pm-options-body {
  display: table-row-group;
}
.pm-options-container {
  max-height: 275px;
  overflow-y: auto;
}

@keyframes spin-animation {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spin:before {
  display: block;
  transform-origin: center center;
  -webkit-backface-visibility: hidden;
  -webkit-animation: spin-animation 2s linear infinite;
  animation: spin-animation 2s linear infinite;
}
