@import "./generate-colors";

/* primary palette */

$ef-primary-palette: (
  50: #e0ebf2,
  100: #b3cee0,
  200: #80adcb,
  300: #4d8cb6,
  400: #2673a6,
  500: #005a96,
  600: #004471,
  700: #004883,
  800: #003f79,
  900: #002e68,
  A100: #97bcff,
  A200: #649bff,
  A400: #317aff,
  A700: #1869ff,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* accenmt palette */

$ef-accent-palette: (
  50: #e8e9ff,
  100: #c4c8ff,
  200: #9aa4ff,
  300: #6980ff,
  400: #3a60ff,
  500: #0042fb,
  600: #0039f0,
  700: #002de3,
  800: #001fd8,
  900: #0000bf,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* grey palette */

$ef-grey-palette: (
  50: #fff,
  100: #f6f6f6,
  200: #efefef,
  300: #d6d6d6,
  400: #8c8c8c,
  500: #595959,
  600: #8f9194,
  700: #616161,
  800: #333,
  900: #000,
  A100: #fff,
  A200: #eee,
  A400: #999,
  A700: #666,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white,
  ),
);

/* define material paletts */

$practicemanagement-primary: mat.define-palette($ef-primary-palette, 500);
$practicemanagement-accent: mat.define-palette($ef-accent-palette, 400);
$practicemanagement-warn: mat.define-palette(mat.$red-palette);
$practicemanagement-grey: mat.define-palette($ef-grey-palette);

/* generate css variables */

@include generateColors(primary, $practicemanagement-primary);
@include generateColors(accent, $practicemanagement-accent);
@include generateColors(warn, $practicemanagement-warn);
@include generateColors(ui, $practicemanagement-grey);

/* misc. colors */

:root {
  --ef-alert-error-background-color: #ffe6e6;
  --ef-alert-error-border-color: #c11b00;
  --ef-alert-error-icon-color: #c11b00;
  --ef-alert-info-background-color: #dcf0f9;
  --ef-alert-info-border-color: #9ec3f0;
  --ef-alert-info-icon-color: inherit;
  --ef-alert-success-background-color: #DFF0D0;
  --ef-alert-success-border-color: #8CD024;
  --ef-alert-success-icon-color: #0D663E;
  --ef-alert-available-icon-color: #62a420;
  --ef-alert-warning-background-color: #fcf8e3;
  --ef-alert-warning-border-color: #c09853;
  --ef-alert-warning-icon-color: #c09853;
  --ef-footer-background-color: #023046;
  --ef-footer-text-color: rgba(255, 255, 255, 0.87);
  --ef-graph-color-1: #62a420;
  --ef-header-navigation-background-color: #fff;
  --ef-header-navigation-text-color: var(--mdc-text-button-label-text-color);
  --ef-header-navigation-underline-color: #023046;
  --ef-header-toolbar-background-color: #023046;
  --ef-header-toolbar-highlight-color: #194259;
  --ef-header-toolbar-text-color: rgba(255, 255, 255, 0.87);
  --ef-link-color: var(--ef-primary-500);
  --ef-link-hover-color: var(--ef-primary-600);
  --ef-negative-color: #c11b00;
  --ef-mask-color: rgba(255, 255, 255, 0.5);
  --ef-menu-item-highlight-color: #005a96;
  --ef-panel-alt-background-color: rgba(224, 235, 242, 0.6);
  --ef-panel-radius: 4px;
  --ef-pinned-note-icon-on-color: #c11b00;
  --ef-table-alternating-color: var(--ef-ui-100);
  --ef-table-border-color: var(--ef-ui-400);
  --ef-table-border-width: 1px;
  --ef-table-corner-radius: 4px;
  --ef-table-header-gradient-end: var(--ef-ui-300);
  --ef-table-header-gradient-start: var(--ef-ui-100);
  --ef-table-hover-row-color: var(--ef-ui-200);
  --ef-text-disabled: #6c757d;
  --ef-text-new-value: #008000;
  --ef-text-old-value: #ff0000;
}
