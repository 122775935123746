/* Header */

pm-navigation,
pm-navigation-claims,
pm-navigation-orders {
  .mat-mdc-button {
    .mdc-button__label {
      border-bottom: 2px solid transparent;
      padding: 8px 0 6px 0;
    }
    .mat-mdc-button-ripple {
      display: none;
    }
    &:hover {
      .mdc-button__label {
        border-bottom-color: var(--ef-header-navigation-underline-color);
      }
    }
    &:not(:focus):hover {
      .mat-mdc-button-persistent-ripple::before {
        background-color: transparent;
      }
    }
    &[aria-expanded="true"] {
      .mdc-button__label {
        border-bottom-color: var(--ef-header-navigation-underline-color);
      }
    }
  }
}
.header-navbar {
  background-color: var(--ef-header-navigation-background-color);
  height: 50px;

  & > div > div > a,
  & > div > div > button {
    border-radius: 0;
    color: var(--ef-header-navigation-text-color) !important;
    display: flex;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
    height: 50px !important;
    padding-left: 12px;
    padding-right: 12px;

    &.dropdown-toggle::after {
      margin-left: 0.2em;
    }
  }
}
.mat-mdc-menu-panel.mobile-nav-menu {
  background-color: #e0ebf2;
  border-radius: 0;
  box-shadow: 0 2px 4px #0003;
  clip-path: inset(0 0 -10px 0);
  max-width: 100vw;
  margin-top: 5px;
  width: 100vw;
  & .mat-mdc-menu-content {
    padding: 0;
    & .mat-accordion .mat-expansion-panel {
      background-color: #e0ebf2;
      border-radius: 0;
      & .mat-expansion-panel-body {
        padding: 0;
        & .mat-mdc-menu-item {
          padding: 0 24px;
        }
      }
      & .mat-expansion-panel-header-title {
        border-bottom: 2px solid transparent;
        flex-grow: 0;
        padding: 8px 0 6px 0;
        text-wrap: nowrap;
        .mat-icon {
          margin-right: 16px;
        }
      }
      &:not(:last-of-type)::after {
        border-bottom-color: var(--ef-ui-400);
      }
      &.mat-expansion-panel-spacing{
        margin: 0;
      }
      .mat-expanded .mat-expansion-panel-header-title {
        border-bottom-color: var(--ef-header-navigation-underline-color);
      }
    }
    .mat-mdc-menu-item.mobile-line-before {
      padding: 0 24px;
      &::before {
        content: "";
        display: block;
        border-top: 1px solid var(--ef-ui-400);
        margin: 0 20px;
      }
    }
  }
}

.cdk-overlay-container,
.cdk-overlay-backdrop,
.cdk-overlay-connected-position-bounding-box,
.cdk-global-overlay-wrapper,
.cdk-overlay-pane {
  z-index: 2000;
}
